@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Rubik&display=swap');

/* ========================== font guide
* font-family: 'Barlow', sans-serif; // base font
* font-family: 'Chango', cursive; // effect font
* font-family: 'Source Sans Pro', sans-serif;  // clean font
*/

body {
  margin: 0;

  /* base font */
  font-family: 'DM Sans';
  font-size: 12px;
  background-color: #ffffff;
}

button {
  font-family: 'DM Sans';

  padding: 0;
  margin: 0;
}
